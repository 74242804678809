export enum AppRoutes {
  Login = "/login",
  LoginEmail = "/login/email",
  LoginSAML = "/login/saml",
  LoginPassword = "/login/password",
  ForgotPassword = "/login/forgot-password",
  ResetPassword = "/login/reset-password",
  Signup = "/signup",
  SignupEmail = "/signup/email",
  SignupSAML = "/signup/saml",
  SignupPassword = "/signup/password",
  Logout = "/logout",
}
